.wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.wrapper:after {
	content: '';
	position: absolute;
	top: 0;
	right: -100px;
	bottom: 0;
	left: -100px;
	z-index: 2;
	display: block;
	width: 150%;
	height: 100%;
	background-image: url('./404-bg.png');
	background-position: right;
	background-size: auto 100%;
	animation: animation 1.6s linear infinite;
}

.text {
	user-select: none;
	display: block;
	margin: 0;
	font-weight: 700;
	font-size: 150px;
	line-height: 1;
	text-align: center;
	color: #eff1f5;
}

@media screen and (min-width: 500px) {
	.text {
		font-size: 240px;
	}

	.wrapper:after {
		animation-name: animation500;
	}
}

@media screen and (min-width: 660px) {
	.text {
		font-size: 316px;
	}

	.wrapper:after {
		animation-name: animation660;
	}
}

@media screen and (min-width: 1350px) {
	.text {
		font-size: 420px;
	}

	.wrapper:after {
		animation-name: animation1350;
	}
}

/* Animation keyframes  */
@keyframes animation1350 {
	from {
		left: -100px;
	}

	to {
		left: -266px;
	}
}

@keyframes animation660 {
	from {
		left: -100px;
	}

	to {
		left: -230px;
	}
}

@keyframes animation500 {
	from {
		left: -100px;
	}

	to {
		left: -200px;
	}
}

@keyframes animation {
	from {
		left: -100px;
	}

	to {
		left: -160px;
	}
}
