.root {
	display: flex;
	min-width: 320px;
	padding: var(--vkws_landings--horizontal-padding);

	scroll-margin-top: 60px;
}

.root-with-breakpoints {
	display: flex;
	width: 300px;
	margin: 0 auto;
	flex-direction: column;
	padding-top: 40px;
	padding-bottom: 40px;
}

.direction-column {
	flex-direction: column;
}

@media screen and (min-width: 1024px) {
	.root {
		max-width: calc(1240px + var(--vkws_landings--horizontal-padding--desktop) * 2);
		margin: auto;
		padding: var(--vkws_landings--horizontal-padding--desktop);

		scroll-margin-top: 65px;
	}

	.root-with-breakpoints {
		width: var(--vkws_landings--with-breakpoints--tablet);
		margin: 0 auto;
		padding: 100px 0;
		flex-direction: row;
	}
}

@media screen and (min-width: 1440px) {
	.root-with-breakpoints {
		width: 1240px;
		margin: 0 auto;
	}
}

@media screen and (min-width: 1680px) {
	.root {
		scroll-margin-top: 85px;
	}
}
