.section-main {
	display: grid;
	padding-bottom: 90px;

	gap: 40px;
}

.text {
	margin: 0;
	padding-bottom: 24px;
	text-align: center;
}

.title {
	margin: 0;
	padding-bottom: 24px;
	font-weight: 500;
	text-align: center;
}

.button-container {
	display: flex;
	justify-content: center;
}

@media screen and (min-width: 1024px) {
	.section-main {
		grid-template-columns: minmax(350px, 605px) minmax(600px, max-content);
	}

	.title {
		padding-bottom: 27px;
		text-align: left;
	}

	.text {
		max-width: 370px;
		padding-bottom: 40px;
		text-align: left;
	}

	.button-container {
		display: flex;
		justify-content: left;
	}
}

@media screen and (min-width: 1350px) {
	.section-main {
		grid-template-columns: minmax(350px, 605px) max-content;
		gap: 25px;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 132px;
	}
}
