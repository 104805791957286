.root {
	height: var(--vkws_landings--portal_header--height);
	background-color: var(--vkws_landings--portal_header--background_color);
	z-index: var(--vkws_landings--portal-menu--z-index);
	position: relative;
}

.root-light {
	--vkws_landings--portal_header--background_color: #fff;
}
