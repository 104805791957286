.root {
	display: block;
	font-size: 18px;
	line-height: 22px;
	margin-top: 16px;
	margin-bottom: 16px;

	color: var(--vkws_landing--footer--menu-text-color);
	opacity: 0.8;
}

.root:hover {
	opacity: 1;
}

.root:last-child {
	margin-bottom: 0;
}
