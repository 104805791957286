.root {
	background-color: var(--vkws_landing--footer--bg);
}

.footer-section {
	padding: 20px 16px 32px;
	margin-left: auto;
	margin-right: auto;
}
.wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
}

.bottom-line {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: column;

	position: relative;
	padding-top: 24px;
}

.bottom-line:before {
	content: "";
	border-top: 1px solid var(--vkws_landing--footer--border-top-color);
	opacity: var(--vkws_landing--footer--border-top-opacity);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;

}

.bottom-line-left,
.bottom-line-right {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}


.bottom-link {
	display: block;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;

	color: var(--vkws_landing--footer--menu-text-color);
	opacity: 0.6;

	margin-top: 6px;
	margin-bottom: 6px;
}

.bottom-link:hover {
	opacity: 1;
}

.bottom-line-text {
	margin-top: 17px;

	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;

	color: var(--vkws_landing--footer_title--color);
	opacity: 0.6;

	margin-bottom: 4px;
}

.title {
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	margin-top: 22px;
	margin-bottom: 24px;

	color: var(--vkws_landing--footer_title--color);
	opacity: 1;
}

.icons {
	margin-top: 24px;

	display: flex;
	align-items: center;
	gap: 16px;
}

.icons svg {
	display: block;
	color: black;

	opacity: 0.6;
}

.icons svg:hover {
	opacity: 1;
}

.vk-business {
	margin-top: 70px;
	margin-bottom: 45px;
}

@media screen and (min-width: 1024px) {
	.footer-section {
		padding: 40px 40px 32px;
	}

	.wrapper {
		display: grid;
		grid-template-columns: 240px 280px 240px;
		grid-gap: 40px;
	}

	.bottom-line {
		align-items: center;
		flex-direction: row;
	}

	.bottom-line-left,
	.bottom-line-right {
		align-items: center;
		flex-direction: row;
	}

	.bottom-line-left > a {
		margin-right: 20px;
	}

	.bottom-line-text {
		margin-top: 4px;
	}

	.icons {
		margin-top: 0;
		margin-left: 24px;
	}
}


@media screen and (min-width: 1440px) {
	.footer-section {
		padding-right: 100px;
		padding-left: 100px;
	}

	.wrapper {
		display: grid;
		grid-template-columns: 240px 280px 240px 240px;
		grid-gap: 40px;
	}
}

@media screen and (min-width: 1540px) {
	.footer-section {
		width: 100%;
		max-width: calc(1600px + var(--vkws_landings--horizontal-padding--desktop) * 2);
	}
}
