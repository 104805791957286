.root {
	overflow-y: auto;
	overflow-x: hidden;
	scroll-behavior: smooth;
}

.header-border {
	position: sticky;
	top: -1px;
	z-index: var(--vkws_landings--header--z-index);

	background: var(--vkws_landing--advanced-menu--bg-color);
	border-bottom: 1px solid transparent;
}

.header-shadow_type_default {
	filter: drop-shadow(0px 4px 4px rgba(72, 72, 72, 0.022)) drop-shadow(0px 12px 17px rgba(72, 72, 72, 0.02));
}

.header-shadow_type_contrast {
	filter: none;
}
